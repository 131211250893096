.Header {
  background-image: url("../../img//Exterior2.jpeg");
  background-color: #0064a7;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  width: 100%;
  height: auto;
}

.BlurCompa {
  height: 100%;
  backdrop-filter: blur(3px);

  background-color: rgba(#dde2e5, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.Header img {
  margin: 50px 60px;
  height: 200px;
  transition: all 0.3s ease-in-out;
}
@media screen and (max-width: 768px) {
  .Header img {
    margin: 10px 0;
    height: 130px;
  }
}
