.footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: #0064a7;
  overflow: hidden;
}

.footer img {
  width: 150px;
  margin: 30px 15px;
}

#con {
  width: 200px;
}

#ed {
  width: 400px;
}

@media screen and (max-width: 768px) {
  .footer {
    justify-content: center;
  }
  .footer img {
    width: 100px;
    margin: 5px;
  }
  #ed {
    width: 175px;
  }
}
