.ModalC img {
  margin: 0px 15px 10px;
  width: 150px;
}

.ModalC .Imagen {
  display: flex;
  align-items: center;
  justify-content: center;
}
