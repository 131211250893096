.Tarjeta {
  text-align: center;
  padding: 20px 10px;
  transition: all 0.3s ease-in-out;
}
.Tarjeta-img {
  align-self: center;
}

.Tarjeta:hover {
  transform: (scale(1.02));
}
#fotoaver {
  width: 100px;
}

#botonchido {
  margin: 10px;
}
@media screen and (max-width: 768px) {
  .Tarjeta {
    padding: 0 10px;
  }
}
