.Descripcion {
  background-image: url("../../img/Exterior4.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  backdrop-filter: blur(2px);
  background-color: rgba(#dde2e5, 0.4);
  height: 300px;
}

.DescripcionBlur {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  background-color: rgba(#000000, 0.75);
}

.Descripcion .DescripcionBlur p {
  text-align: center;
  margin: 30px 100px;
  font-size: 1.2rem;
  color: white;
  font-weight: bolder;
}

@media screen and (max-width: 960px) {
  .Descripcion .DescripcionBlur p {
    font-size: 15px;
    margin: 20px 50px;
  }
}

@media screen and (max-width: 680px) {
  .Descripcion .DescripcionBlur p {
    font-size: 13px;
    margin: 10px 10px;
  }
}

@media screen and (max-width: 440px) {
  .Descripcion .DescripcionBlur p {
    font-size: 10px;
    margin: 10px 10px;
  }
}
