.magistrales {
  width: 100%;
}

#tarjeta {
  transition: all 0.3s ease-in-out;
}

#tarjeta:hover {
  transform: scale(1.05);
}

.magistrales__container {
  padding: 0;
}

.magistrales__banner {
  background-color: #4384e4;
  padding: 10px;
  margin-bottom: 20px;
  width: 100%;
}

.magistrales__title {
  color: white;
  margin: 15px 0;
  text-align: center;
}

.magistrales__col {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.card {
  padding: 10px;
  border: none;
}

.card-title.h5 {
  text-transform: capitalize;
  font-size: 2rem;
}

.card-text {
  overflow: hidden;
  text-overflow: ellipsis;
  height: 150px;
}

#magistralItem__img {
  align-self: center;
  width: 90%;
}

@media screen and (max-width: 768px) {
  .card {
    padding: 0 10px;
  }
}
