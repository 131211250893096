.SliderContainer {
  background-image: url("../../img/slider_background.png");
  background-size: contain;
  width: 100%;
  padding: 3% 5%;
  display: flex;
  justify-content: center;
}

.Slider {
  width: 70%;
  height: auto;
}

@media screen and (max-width: 768px) {
  .SliderContainer {
    padding: 0;
  }
  .Slider {
    width: 100%;
  }
}
